import React from "react"
import { Helmet } from "react-helmet"

const IePage = () => {
    return (
        <>
        <Helmet>
            <meta name="robots" content="noindex, nofollow, noimageindex" data-no-index="true" />
        </Helmet>
        <div className="h-screen w-screen" style={{height: "100%", width: "100%"}}>
            <h1 className="font-cursive text-6xl py-4 text-center" style={{color: "blueviolet"}}>Rokit Media</h1>
            <article className="container mx-auto max-w-7xl text-left py-12 px-6 md:px-12" style={{backgroundColor: "blueviolet"}}>
                <h2 className="font-cursive text-6xl pb-4" style={{color: "white"}}>Hello and welcome</h2>
                <p className="font-mono text-xl md:text-4xl" style={{color: "white"}}>It looks like you are using a prehistoric browser! 
                    maybe Internet Explorer! (Oh No) We do not support ancient browsers, 
                    please update to a modern browser to use our website...</p>
                <br />
                <p className="font-mono text-xl md:text-4xl" style={{color: "white"}}>If you are using Internet Explorer, 
                you should update to 
                <a href="https://www.microsoft.com/en-us/edge" alt="Microsoft Edge" target="_blank" rel="noreferrer" style={{color: "lightblue"}}> Microsoft Edge</a>.
                 You could consider using 
                 <a href="https://www.google.com/chrome/" alt="Google Chrome" target="_blank" rel="noreferrer" style={{color: "lightblue"}}> Chrome</a>, 
                 <a href="https://www.mozilla.org/en-GB/firefox/new/" alt="Google Chrome" target="_blank" rel="noreferrer" style={{color: "lightblue"}}>Firefox </a>
                  or 
                  <a href="https://www.opera.com/" alt="Google Chrome" target="_blank" rel="noreferrer" style={{color: "lightblue"}}> Opera </a>
                   on windows for a better browsing experience. 
                If you are using 
                <a href="https://support.apple.com/en_GB/downloads/safari" alt="Google Chrome" target="_blank" rel="noreferrer" style={{color: "lightblue"}}> Safari </a>on a Mac, make sure you are using an updated version.</p>
            </article>
        </div>
        </>
    )
}

export default IePage